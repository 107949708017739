body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

/* Navabr */

.nav-links {
  color: white;

  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  font-size: smaller;
  font-weight: 600;
}
.nav-links:hover {
  background-color: rgb(37, 63, 64);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

/* dashboard */

.divider {
  border-top: 3px solid rgb(37, 63, 64);
  width: 100%;
}
.dashboardContent {
  text-align: left;
  margin-top: 205px;
  margin-left: 155px;
  /* max-width: 600px; */
  color: #ffff;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 60%);

  /* font-weight: 600; */
  font-style: normal;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 1.2em;
}
.dashboardContent2 {
  font-family: Verdana, Geneva, sans-serif;

  text-align: left;

  margin-left: 155px;
  /* max-width: 600px; */
  color: #ffffff;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 60%);
  margin-top: 0;
  font-weight: 600;
  font-style: normal;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 1.2em;
}
.dashboardbutton {
  margin-left: 155px;
}
.dashboard {
  width: 100%;
  height: 800px;
  padding-top: 20px;

  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/static/media/home.4a575dea.jpeg);

  opacity: 1;
  /* background-image: url("/src/components/Images/build.jpg"); */
  background-size: cover;
  background-attachment: fixed;
}
/* AboutUs */
.imgMap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  background-size: cover;
  opacity: 0.7;
}
.imgMap :hover {
  transform: translateY(-4px);
}
.gridMap {
  margin: 20px;
  padding-left: 50px;
}
.gridAbout {
  margin: 20px;
  padding-right: 50px;
}
.paraAbout {
  /* line-height: 1.8; */
  /* margin: 5px 0px 15px; */
  /* font-size: 20px; */
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(119, 125, 111);
  line-height: 2;
  /* line-break: anywhere; */
}
/* heading */
.common-head {
  color: rgb(37, 63, 64);
  text-align: center;
  padding-top: "70px";
  font-size: 30px;
  font-weight: 600;
}
.common-devider {
  text-align: center;
  width: 80px;
  border-top: solid rgb(37, 63, 64);
} /* footer */
.footer-img-logo {
  width: 300px;
  /* height: 300px; */
}

.footer-para {
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
}
.footer-head {
  color: #fff;
  font-weight: 600;
}
/* Shedule */
.scedule-form-title {
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.05em;
  line-height: 1.2em;
  text-transform: none;
  font-family: Verdana, Geneva, sans-serif;
}
.scedule-form-para {
  line-height: 1.8;
  margin: 5px 0px 15px;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  color: #808080;
}
.scedule-dashboard {
  width: 100%;
  height: 400px;
  padding-top: 20px;

  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/static/media/home.4a575dea.jpeg);

  opacity: 1;
  /* background-image: url("/src/components/Images/build.jpg"); */
  background-size: cover;
  background-attachment: fixed;
}
/* Request */
.request-container {
  margin-top: 150px;
  margin-bottom: 150px;
}

/* what we do */
.whatwedo-container {
  width: 100%;
  /* min-height: 350px; */
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/static/media/home.4a575dea.jpeg);

  opacity: 1;
  /* background-image: url("/src/components/Images/build.jpg"); */
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
}
.whatwedo-head {
  margin-bottom: 55px;
}
.whatwedo-content-head {
  /* color: rgb(119, 125, 111); */

  /* text-align: left;
  margin-top: 205px;
  margin-left: 155px; */
  /* max-width: 600px; */

  /* font-weight: 600; */

  /* letter-spacing: 0em; */
  /* line-height: 1.2em; */

  /* max-width: 600px; */
  color: #ffff;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 60%);

  font-weight: 500;
  font-style: normal;
  font-size: 37px;
  letter-spacing: 0em;
  line-height: 1.2em;

  padding: 140px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.whatwedo-para {
  /* color: rgb(119, 125, 111); */
}
.whatwedo-icon:hover {
  transform: translateY(-4px);
}
.Service-start-head {
  font-size: 32px;
  text-align: left;

  margin-left: 155px;
  /* max-width: 600px; */
  color: #ffff;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 60%);

  /* font-weight: 600; */
  font-style: normal;

  letter-spacing: 0em;
  line-height: 1.2em;
}
.Service-start-content {
  text-align: left;
  margin-top: -19px;
  margin-left: 155px;
  /* max-width: 600px; */
  color: #ffff;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 60%);

  /* font-weight: 600; */
  font-style: normal;
  font-size: 30px;
  letter-spacing: 0em;
  line-height: 1.2em;
}
/* how it works */
.howitworks-head {
  margin-bottom: 55px;
  color: rgb(37, 63, 64);
}
/* .howwedo-icon :hover {
  transform: translateY(-4px);
} */
.howwedo-head {
  color: rgb(37, 63, 64);
}
.howwedo-para {
  color: rgb(119, 125, 111);
}

/* for phone call  desktop */
.phonecall {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 220px;
  right: 40px;
  background-color: #2553d3;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 40px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 150px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}
/* Home Alert */
.alert-home-img {
  width: 100%;
}
/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }
  .phone-icon {
    margin-top: 8px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 160px;
    right: 10px;
    font-size: 22px;
  }
  .phonecall {
    width: 40px;
    height: 40px;
    bottom: 208px;
    right: 10px;
    font-size: 22px;
  }
}
@media (max-width: 1000px) {
  /* dashboard */
  .Service-start-head {
    /* font-size: 15px; */
    text-align: center;
    /* padding: 10px; */
    /* margin-top: 125px; */
    margin-left: 0px;
    text-shadow: none;
  }
  .Service-start-content {
    margin-top: -11px; /* font-size: 15px; */
    text-align: center;
    /* padding: 10px; */
    /* margin-top: 125px; */
    font-size: 16px;
    margin-left: 0px;
    text-shadow: none;
  }
  .dashboard {
    height: 570px;
    text-align: center;
  }
  .request-container {
    margin-top: 28px;
    margin-bottom: 50px;
  }
  .whatwedo-container {
    height: 100%;
  }
  .whatwedo-head {
    margin-bottom: 55px;
  }
  .dashboardContent {
    font-size: 18px;
    text-align: center;
    /* padding: 10px; */
    margin-top: 125px;
    margin-left: 0px;
    text-shadow: none;
  }
  .Service-start-head {
    font-size: 20px;
  }
  .dashboardContent2 {
    font-size: 15px;
    text-align: center;
    /* padding: 10px; */

    margin-left: 0px;
    text-shadow: none;
  }
  .whatwedo-content-head {
    padding: 0px;
    font-size: 19px;
    font-weight: 500;
  }
  .dashboardbutton {
    margin-left: 0px;
    /* margin-top: 30px; */
  } /* aboutus */
  .imgMap {
    max-width: 300px;
  }
  .gridMap {
    margin: 5px;
    padding-left: 10px;
  }
  .gridAbout {
    margin: 5px;
    padding-right: 5px;
  }
  .paraAbout {
    padding-left: 35px;
    padding-right: 35px;
    margin: 10px;
    font-size: 14px;
    padding-top: 15px;
  } /* footer */
  .footer-img-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-para {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
  }
  .footer-head {
    font-size: 16px;
  }
}

/*Desktop View*/
.header {
  display: flex;
  /* background-color: blanchedalmond; */
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: 80px;
}
.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 160px;
  height: 160px;
}
.nav-options {
  padding-left: 20px;
  display: grid;

  grid-template-columns: repeat(4, auto);
  grid-gap: 30px;
  list-style-type: none;
}
.mobile-option {
  display: none;
}

.option :hover {
  color: white;

  /* transition: all 0.2s ease-out; */
}
.signin-up {
  display: flex;
  padding: 0px 5px;
  list-style-type: none;
  text-decoration: none;
}
.sign-in {
  padding-right: 50px;
  align-self: center;
}
.sign-in :hover {
  color: white;
}
.signup-btn {
  padding: 10px 10px;
  height: 2.4rem;
  border-radius: 3px;
  background: rgb(222, 9, 241);
  color: white;
  cursor: pointer;
  align-items: center;
}
.mobile-view {
  display: none;
}
.nav-links {
  color: white;

  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
.nav-links:hover {
  background-color: rgb(37, 63, 64);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
.topnav-container {
  text-align: right;
  padding-right: 53px;
}
.mobNum {
  color: white;
  font-size: 13px;
  margin-right: 19px;
}
.email-nav {
  color: white;
  font-size: 13px;
  margin-right: 19px;
}
@media (max-width: 900px) {
  /*Mobile View */
  .topnav-container {
    display: none;
  }
  .header {
    padding: 0px 10px;
  }
  .logo {
    width: 130px;
    height: 130px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    height: 350px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(/static/media/home.4a575dea.jpeg);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
    height: 100vh;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
  }
  .sign-up {
    background: rgb(222, 9, 241);
    border-radius: 3px;
    color: white;
    padding: 20px 0px;
    width: 80%;
    align-self: center;
    border-radius: 3px;
  }

  .signin-up {
    display: none;
  }
  .mobile-view {
    display: block;
  }
}

