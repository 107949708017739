/*Desktop View*/
.header {
  display: flex;
  /* background-color: blanchedalmond; */
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: 80px;
}
.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 160px;
  height: 160px;
}
.nav-options {
  padding-left: 20px;
  display: grid;

  grid-template-columns: repeat(4, auto);
  grid-gap: 30px;
  list-style-type: none;
}
.mobile-option {
  display: none;
}

.option :hover {
  color: white;

  /* transition: all 0.2s ease-out; */
}
.signin-up {
  display: flex;
  padding: 0px 5px;
  list-style-type: none;
  text-decoration: none;
}
.sign-in {
  padding-right: 50px;
  align-self: center;
}
.sign-in :hover {
  color: white;
}
.signup-btn {
  padding: 10px 10px;
  height: 2.4rem;
  border-radius: 3px;
  background: rgb(222, 9, 241);
  color: white;
  cursor: pointer;
  align-items: center;
}
.mobile-view {
  display: none;
}
.nav-links {
  color: white;

  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
.nav-links:hover {
  background-color: rgb(37, 63, 64);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
.topnav-container {
  text-align: right;
  padding-right: 53px;
}
.mobNum {
  color: white;
  font-size: 13px;
  margin-right: 19px;
}
.email-nav {
  color: white;
  font-size: 13px;
  margin-right: 19px;
}
@media (max-width: 900px) {
  /*Mobile View */
  .topnav-container {
    display: none;
  }
  .header {
    padding: 0px 10px;
  }
  .logo {
    width: 130px;
    height: 130px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    height: 350px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("../../Assets/home.jpeg");
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
    height: 100vh;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
  }
  .sign-up {
    background: rgb(222, 9, 241);
    border-radius: 3px;
    color: white;
    padding: 20px 0px;
    width: 80%;
    align-self: center;
    border-radius: 3px;
  }

  .signin-up {
    display: none;
  }
  .mobile-view {
    display: block;
  }
}
